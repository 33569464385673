@import "~@arctravel/react-fds/lib/designTokens/color.scss";

.exportIcons {
    text-align: right;
    position: relative;
    z-index: 1;

    i {
        font-size: 1.5rem;
        position: relative;
        top: 4px;
    }
    span {
        margin-left: 1.2rem;
        cursor: pointer;
    }
}

.longRunningPopup .modal-dialog {
    max-width: 600px;
}

.filterListWrap {
    max-height: 120px;
    overflow: auto;
}

div.empty {
    width: 100%;
    + .exportIcons {
        display: none;
    }
}

.fds-table.infinite {
    margin-bottom: 0;
    tbody {
        max-height: calc(100vh - 260px);
    }
}

.resultsWrap {
    overflow: auto hidden;
}

.backMenu {
    background: #fff5f5;
    border-radius: 8px;
    width: fit-content;
    ul {
        list-style: none;
        white-space: nowrap;
        padding: 0 6px;
        li {
            display: inline-block;
            padding: 0 24px 0 8px;
            cursor: pointer;
            font-weight: 600;
            white-space: nowrap;
            span {
                font-weight: bolder;
                font-size: 20px;
            }
        }
    }
}

[class*="PivoteBg"] {
    vertical-align: middle !important;
    white-space: normal !important;
}

.resultsLoading.position-absolute {
    position: relative !important;
    td {
        border-top: none;
        border-bottom: none;
    }
}

.reportingPage {
    .type-caption-on-light,
    .exportIcons,
    .fds-table-wrap {
        white-space: nowrap;
    }

    .darkbg {
        background: #d9d9d9;
        &.spacing {
            padding: 0rem 1rem 1rem 4rem;
        }
    }

    #resultsWrapBackdrop {
        display: none;
    }
    .resizeIcon {
        display: none;
    }
    #reportPageLeftToggleRef.min {
        + #resultsWrapBackdrop {
            &.show {
                display: block;
                z-index: 99;
            }
            + #resultsWrap {
                overflow: auto hidden;
                .fds-table-wrap,
                .fds-table {
                    margin-bottom: 0;
                }
                i[class*="clear"] {
                    display: none;
                    font-size: 1.5rem;
                    color: #949494;
                    &:hover {
                        color: #6a6969;
                    }
                }
                i[class*="resize"] {
                    display: block;
                }
                &.expanded {
                    position: fixed;
                    top: 1%;
                    left: 1%;
                    background: white;
                    width: 98%;
                    height: 98%;
                    border: 1px solid rgba(0, 0, 0, 0.175);
                    border-radius: 0.5rem;
                    z-index: 100;
                    .resultsWrap {
                        max-height: calc(95vh - 100px);
                    }
                    .resizeIcon {
                        cursor: pointer;
                        i[class*="resize"] {
                            display: none;
                        }
                        i[class*="clear"] {
                            display: block;
                        }
                    }
                    .printWrap {
                        visibility: hidden !important;
                    }
                }
                .resizeIcon {
                    width: 56px;
                    height: 56px;
                    display: inline-block;
                    flex: none;
                    cursor: pointer;
                    i[class*="resize"] {
                        font-size: 1.6rem;
                        color: #949494;
                        &:hover {
                            color: #6a6969;
                        }
                    }
                }
            }
        }
    }
}

#sortIcon {
    margin-left: 4px;
    margin-top: 4px;
    font-size: 1.5rem;
}
.sortLabel {
    width: 70%;
    display: inline-block;
    overflow: hidden;
    text-overflow: ellipsis;
}

#emptyResultWrap {
    padding: 10rem 0;
    text-align: center;
    color: $gray-400;
    .icon {
        display: inline-block;
        font-size: 2rem;
        transform: rotate(-30deg);
    }
}
