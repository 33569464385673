@import "~@arctravel/react-fds/lib/designTokens/color.scss";

.homePage {
    background: #f6f8f8;
    .fds-pl .fds-pl-header-copy {
        //width: calc(100% - 2rem);
    }
    .fds-pl .fds-pl-jumbotron {
        padding: 0 0 1.8rem;
    }
    .fds-pl {
        .fds-pl-header-copy.container {
            h1 {
                font-size: 1.4rem;
                font-weight: 500;
                margin-bottom: 0;
                line-height: 1rem;
                margin-top: 0rem;
            }
            h2 {
                font-size: 2rem;
                font-weight: bold;
                margin-top: 1.6rem;
                margin-bottom: 0;
            }
        }
        .fds-pl-column {
            border-radius: 0;
            box-shadow: none !important;
            padding: 0 !important;
        }
    }
    .fds-pl-container {
        margin-bottom: 0;
        &.first {
            margin-top: 0;
        }
        .fds-pl-column {
            background-color: #f6f8f8;
        }
    }

    .leftWrap {
        position: absolute;
        top: 16px;
        right: 0;
        z-index: 10;
        width: 64px;
        height: 64px;
        border: none;
        background: transparent;
        padding: 0 1rem;
        text-align: right;
        cursor: pointer;
        i {
            color: white;
            font-size: 2rem;
        }
        &:hover {
            width: 285px;
            height: auto;
            .card {
                height: calc(100% - 5rem);
                background-color: white;
            }
        }
        .card {
            height: 0;
            overflow: hidden;
            background-color: transparent;
            border: none;
            text-align: left;
            cursor: default;
        }
    }
    .cardWraps {
        background-color: #f6f8f8;
    }
    .card {
        border-radius: 0;

        &.appsWrap {
            margin: 1rem 0;
            cursor: pointer;
            .logo {
                width: 2rem;
                height: 2rem;
            }
            .reportName {
                padding-left: 1rem;
                font-size: 20px;
            }
        }
    }
    .viewall {
        text-align: right;
        font-size: 1rem;
    }

    .chartWraps {
        display: none;
        h1 {
            text-align: center;
            color: $primary;
        }
        .chartWrap {
            .col {
                .CircularProgressbar {
                    height: 300px;
                }
                div {
                    text-align: center;
                    padding-top: 1rem;
                }
            }
        }
    }

    .qaWraps {
        display: none;
        background-color: #f6f8f8;
        margin-bottom: -15px;
        color: #393b40;
        .colWrap {
            align-items: center;
            justify-content: center;
            display: flex;
            padding: 2rem 15% 0 15%;

            h1 {
                text-align: center;
                color: $primary;
            }
            ul {
                display: flex;
                justify-content: center;
                margin-bottom: 1.5rem;
                button {
                    background-color: transparent;
                }
            }
            .tab-content {
                [class*="col"] {
                    margin-bottom: 4rem;
                }
                .q {
                    font-weight: 700;
                    padding-bottom: 2rem;
                }
                .a {
                }
            }
        }
    }
}

.fade.alert.alert-primary.show {
    margin-bottom: 0;
    opacity: 1;
    transition: all 0.2s linear;
}

.alert button.close:not(:disabled):not(.disabled):focus {
    outline: none !important;
}
